.App {
  width: 100vw;
  height: 100vh;
}

body {
  background-color: rgb(243, 243, 243);
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Times New Roman', Times, serif;
}




@media only screen and (min-width: 0px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (min-width: 700px) and (min-height: 320px) {
  .sidebar {
    display: block;
  }
  .topBar {
    display: none;
  }
  .topBar #stickyTop {
    display: none;
  }
  .sansTopBarSpace {
    height: 8vh;
  }
  .SidebarList .logoSidebar {
    display: none;
  }
}

@media only screen and (min-width: 1080px) and (min-height: 550px) {
  .sidebar {
    display: block;
  }

  .topBar {
    display: none;
  }
  .topBar #stickyTop {
    display: none;
  }
  .sansTopBarSpace {
    height: 8vh;
  }

  .SidebarList .logoSidebar {
    display: block;
  }
}





.section {
  height: 100%;
}

.section .current #emailImage1 {
  border-radius: 1%;
  max-width: 70vw;
  padding-bottom: 2vh;
  max-height: 40vh;
}

.section .images #image {
  border-radius: 1%;
  max-height: 60vh;
  max-width: 60vw;
}

.artCaption {
  display: block;
  font-size: small;
  margin: auto;
  max-width: 80vw;
  padding-top: 1vh;
  padding-bottom: 4vh;
  line-height: 1.5vh;
}

.artCaption .artTitleYear #artTitle {
  font-style: italic;
}

.current {
  display: block;
  justify-content: center;
}

.current #currentSpace2 {
  height: 2vh;
}

.current #currentSpace3 {
  height: 1vh;
}

.current #currentSpace4 {
  height: 7vh;
}

.current #currentSpace5 {
  height: 5vh;
}

.current #homeText{
  display: block;
}

.current #info {
  background-color: rgb(243, 243, 243);
  width: 200px;
  margin: auto;
  border-radius: 2%;
  padding-bottom: 1vh;
}

.current #homeNames {
  margin: auto;
  font-style: italic;
  height: 25px;
  width: 200px;
}

.current #homeOther{
  margin: auto;
  font-size: small;
  height: 25px;
  width: 200px;
  padding: 0;
}

.current #homeEmail{
  font-size: small;
  background-color: rgb(243, 243, 243);
  margin: auto;
  width: 200px;
  border-radius: 2%;
}

.emailOuterDiv #email {
  color: black;
  font-size: 18px;
  font-weight: thin;
  background-color: rgb(243, 243, 243);
  padding: 1vh;
  border-radius: 50%;
}

.emailOuterDiv #email:hover {
  color: grey;
  transform: translateY(2px);
  cursor: pointer;
}

.emailOuterDiv #email:active {
  color: grey;
  cursor: pointer;
}

.infoHome #icon {
  font-size: 18px;
  color: black;
}

.infoHome #icon:hover {
  font-size: 18px;
  color: grey;
  transform: translateY(2px);
  cursor: pointer;
}




.container {
  max-width: 100vw;
  margin: 0;
}

.flex-grid-1 {
  margin: 0;
  display: block;
}

.flex-grid-2 {
  display: flex;
  margin: 0;
  width: 100%;
}

.col {
  margin: 0;
  padding: 0;
  text-align: center;
}

.sidebar {
  z-index: 999;
  margin: 0;
  position: fixed;
  top: 0;
}

.main {
  width: 100%;
  margin: 0;
  height: 100vh;
}



.topBar { 
  margin: 0;
  height: 12vh;
}

.topBar #stickyTop {
  margin: 0;
  position: fixed;
  top: 0;
  height: 0vh;
  z-index: 998;
}



div#centeredmenu {
  margin: 0;
	float:left;
	position:relative;
	margin:0;
  width: 100vw;
  background-color: rgb(243, 243, 243);
}

div#centeredmenu ul {
  display: block;
}

div#centeredmenu ul {
	clear:left;
	float:right;
	list-style:none;
	margin:0;
	padding:0;
	position:relative;
	right:50%;
	text-align:center;
}

div#centeredmenu ul li {
	display:block;
	float:left;
	list-style:none;
	margin:0;
	padding:0;
	position:relative;
	left:50%;
  max-width: 40vw;
}

div#centeredmenu ul .activeTop #icon {
  color: grey;
}

div#centeredmenu ul li #topIcon {
	display: block;
	margin: 0;
	padding: 20px;
	text-decoration:none;
  color:black;
}

div#centeredmenu ul li #topIcon:hover {
	color:grey;
  transform: translateY(2px);
  cursor: pointer;
}

div#centeredmenu ul li #icon {
  background-color: rgb(243, 243, 243);
  font-size: 20px;
  font-weight: thin;
  border-radius: 25%;
}



.Sidebar {
  height: 100vh;
  width: 75px;
  display: block;
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.SidebarList .row {
  width: 100%;
  height: 80px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: center;
  align-items: center;
}

.SidebarList .logoSidebar {
  list-style-type: none;
}

.SidebarList .row:hover {
  cursor: pointer;
}

.SidebarList #active {
  color: grey;
}

.SidebarList #icon {
  font-size: 20px;
}

.SidebarList #icon:hover {
  color: grey;
  transform: translateY(2px);
}

.row #iconSpace {
  display: grid;
  place-items: center;
}

.SidebarList #logo {
  list-style-type: none;
  padding-left: 1vw;
  height: 200px;
  padding-bottom: 1vh;
}

.SidebarList #logo:hover {
  transform: translateY(8px);
}



.carousel {
  overflow: hidden;
  max-height: 100vh;
}

.inner {
  white-space: nowrap;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.indicators #carouselButton {
  width: 15vw;
}

.indicators #carouselIcon {
  font-size: 18px;
}

.indicators #carouselIcon:hover {
  color: grey;
  cursor: pointer;
}

.carousel #carouselBottom {
  height: 2vh;
}

.slideSection .slideImages #image {
  max-height: 75vh;
  max-width: 75vw;
}

.slideImages #carouselTop {
  height: 10vh;
}









